import "./RatePage.scss";
import RatePageHeader from "./RatePageHeader/RatePageHeader";
import RatePageTable from "./RatePageTable/RatePageTable";
import { useState, useEffect } from "react";

function RatePage() {
  const [selectedColumn, setSelectedColumn] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 775);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 775);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="rate-section">
      <div className="container">
        <div className="rate-section__content">
          <RatePageHeader setSelectedColumn={setSelectedColumn} />
          <RatePageTable selectedColumn={selectedColumn} isDesktop={isDesktop} />
        </div>
      </div>
    </section>
  );
}

export default RatePage;
