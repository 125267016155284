import React, { useState, useEffect } from "react";
import "./SecondSection.scss";

import goldenArrow from "./images/arrow-right-gold.svg";
import blackArrow from "./images/arrow-right-black.svg";
import check from "./images/check.png";

import { useTranslation, Trans } from "react-i18next";

// Define content for each tab
const tabContent = {
	1: {
		header: "secondsectiontabscontent1header",
		header2: "secondsectiontabscontent1header2",
		texts: [
			"secondsectiontabscontent1text1",
			"secondsectiontabscontent1text2",
			"secondsectiontabscontent1text3",
		],
	},
	2: {
		header: "secondsectiontabscontent2header",
		header2: "secondsectiontabscontent2header2",
		texts: ["secondsectiontabscontent2text1", "secondsectiontabscontent2text2"],
	},
	3: {
		header: "secondsectiontabscontent3header",
		header2: "secondsectiontabscontent3header2",
		texts: ["secondsectiontabscontent3text1", "secondsectiontabscontent3text2"],
	},
	4: {
		header: "secondsectiontabscontent4header",
		header2: "secondsectiontabscontent4header2",
		texts: ["secondsectiontabscontent4text1", "secondsectiontabscontent4text2"],
	},
	5: {
		header: "secondsectiontabscontent5header",
		header2: "secondsectiontabscontent5header2",
		texts: ["secondsectiontabscontent5text1", "secondsectiontabscontent5text2", "secondsectiontabscontent5text3"],
	},
};

function SecondSection() {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(1);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		// Initial check
		handleResize();

		// Add event listener
		window.addEventListener("resize", handleResize);

		// Clean up event listener
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleTabClick = (tabIndex) => {
		setActiveTab(tabIndex);

		if (isMobile) {
			setTimeout(() => {
				const element = document.getElementById(`tab-content-${tabIndex}`);
				if (element) {
					const headerOffset = 108; // Adjust this value to match your header height
					const elementPosition =
						element.getBoundingClientRect().top + window.pageYOffset;
					const offsetPosition = elementPosition - headerOffset;

					window.scrollTo({
						top: offsetPosition,
						behavior: "smooth",
					});
				}
			}, 100);
		}
	};

	const renderTabContent = () => {
		const content = tabContent[activeTab];
		if (!content) return null;

		return (
			<div
				id={`tab-content-${activeTab}`}
				className="second-section__content-tabs__content"
			>
				<h4>{t(content.header)}</h4>
				<h2>{t(content.header2)}</h2>
				{content.texts.map((textKey, index) => (
					<p key={index}>
						<img src={check} alt={t(textKey)} />
						<span>
							<Trans i18nKey={textKey} />
						</span>
					</p>
				))}
			</div>
		);
	};

	return (
		<section className="second-section">
			<div className="container">
				<div className="second-section__content">
					<div className="second-section__content-header">
						<h2>{t("secondsectionh2")}</h2>
						<p>{t("secondsectionh2__p")}</p>
					</div>
					<div className="second-section__content-tabs">
						<div className="second-section__content-tabs__items">
							{Object.keys(tabContent).map((index) => (
								<div
									key={index}
									className={`second-section__content-tabs__items-item ${
										activeTab === Number(index) ? "active" : ""
									}`}
								>
									<span className={activeTab === Number(index) ? "active" : ""}>
										{String(index).padStart(2, "0")}
									</span>
									<button
										className={activeTab === Number(index) ? "active" : ""}
										onClick={() => handleTabClick(Number(index))}
									>
										<p>{t(`secondsectiontabs${index}`)}</p>
										<img
											src={
												activeTab === Number(index) ? goldenArrow : blackArrow
											}
											alt={t(`secondsectiontabs${index}`)}
										/>
									</button>
									{/* Content for active tab in mobile view */}
									{activeTab === Number(index) && (
										<div className="second-section__content-tabs__content-mobile">
											{renderTabContent()}
										</div>
									)}
								</div>
							))}
						</div>
						<div className="second-section__content-tabs__content-desktop">
							{renderTabContent()}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SecondSection;
