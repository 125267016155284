import React, { useState } from "react";
import "./WhyUsPage.scss";

import first from "./images/1.svg";
import second from "./images/2.svg";
import third from "./images/3.svg";
import four from "./images/4.svg";
import five from "./images/5.png";
import check from "./images/check.svg";
import slideOne from "./images/slide-1.png";
import arrowRight from "./images/arrow-right2.png"; // Импортируем изображение стрелки

import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

function WhyUsPage() {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(1);

  const slides = [
    {
      id: 1,
      title: t("whyusslide1h3"),
      description: t("whyusslide1p"),
    },
    {
      id: 2,
      title: t("whyusslide2h3"),
      description: t("whyusslide2p"),
    },
    {
      id: 3,
      title: t("whyusslide3h3"),
      description: t("whyusslide3p"),
    },
    {
      id: 4,
      title: t("whyusslide4h3"),
      description: t("whyusslide4p"),
    },
  ];

  return (
    <section className="why-section">
      <div className="container">
        <div className="why__content">
          <div className="why__content-header">
            <h2>{t("whyusheaderh2")}</h2>
            <p>{t("whyusheaderp")}</p>
          </div>
          <div className="why__content-firstblocks">
            <div className="why__content-firstblocks__items">
              <div className="why__content-firstblocks__items-item">
                <div className="why__content-firstblocks__items-item-img">
                  <img src={first} alt="" />
                </div>
                <div className="why__content-firstblocks__items-item-text">
                  <h4>{t("whyusfirstblock1")}</h4>
                  <p>{t("whyusfirstblock11")}</p>
                </div>
              </div>
              <div className="why__content-firstblocks__items-item">
                <div className="why__content-firstblocks__items-item-img">
                  <img src={second} alt="" />
                </div>
                <div className="why__content-firstblocks__items-item-text">
                  <h4>{t("whyusfirstblock2")}</h4>
                  <p>{t("whyusfirstblock21")}</p>
                </div>
              </div>
              <div className="why__content-firstblocks__items-item">
                <div className="why__content-firstblocks__items-item-img">
                  <img src={third} alt="" />
                </div>
                <div className="why__content-firstblocks__items-item-text">
                  <h4>{t("whyusfirstblock3")}</h4>
                  <p>{t("whyusfirstblock31")}</p>
                </div>
              </div>
              <div className="why__content-firstblocks__items-item">
                <div className="why__content-firstblocks__items-item-img">
                  <img src={four} alt="" />
                </div>
                <div className="why__content-firstblocks__items-item-text">
                  <h4>{t("whyusfirstblock4")}</h4>
                  <p>{t("whyusfirstblock41")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="why__content__secondHeader">
            <div className="why__content__secondHeader-text">
              <h2>{t("whyussecondblockh2")}</h2>
              <h3>{t("whyussecondblockp")}</h3>
              <p>{t("whyussecondblockp2")}</p>
            </div>
            <div className="why__content__secondHeader-img">
              <img src={five} alt="" />
            </div>
          </div>
          <div className="why__content__secondblocks">
            <h2>{t("whyusthirdblockh2")}</h2>
            <div className="why__content__secondblocks-items">
              <div className="why__content__secondblocks-items-item">
                <div className="why__content__secondblocks-items-item-img">
                  <img src={check} alt="" />
                </div>
                <div className="why__content__secondblocks-items-item-text">
                  <h5>{t("whyusthirdblock1")}</h5>
                  <p>{t("whyusthirdblock11")}</p>
                </div>
              </div>
              <div className="why__content__secondblocks-items-item">
                <div className="why__content__secondblocks-items-item-img">
                  <img src={check} alt="" />
                </div>
                <div className="why__content__secondblocks-items-item-text">
                  <h5>{t("whyusthirdblock2")}</h5>
                  <p>{t("whyusthirdblock22")}</p>
                </div>
              </div>
              <div className="why__content__secondblocks-items-item">
                <div className="why__content__secondblocks-items-item-img">
                  <img src={check} alt="" />
                </div>
                <div className="why__content__secondblocks-items-item-text">
                  <h5>{t("whyusthirdblock3")}</h5>
                  <p>{t("whyusthirdblock33")}</p>
                </div>
              </div>
              <div className="why__content__secondblocks-items-item">
                <div className="why__content__secondblocks-items-item-img">
                  <img src={check} alt="" />
                </div>
                <div className="why__content__secondblocks-items-item-text">
                  <h5>{t("whyusthirdblock4")}</h5>
                  <p>{t("whyusthirdblock44")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="why__content__slider">
            <h2>{t("whyussliderh2")}</h2>
            <div className="why__content__slider-inner">
              <div className="why__content__slider-inner-img">
                <img src={slideOne} alt="" />
              </div>
              <div className="why__content__slider-inner-text">
                <Swiper
                  modules={[Navigation]}
                  navigation={{
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                  }}
                  spaceBetween={50}
                  slidesPerView={1}
                  onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex + 1)}
                  onInit={(swiper) => {
                    swiper.navigation.init();
                    swiper.navigation.update();
                  }}
                >
                  {slides.map((slide) => (
                    <SwiperSlide key={slide.id} className="slide">
                      <div className="slide-content">
                        <h2>{slide.title}</h2>
                        <p>{slide.description}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <button className="swiper-button-prev">
                  
                </button>
                <button className="swiper-button-next">
                  
                </button>
              </div>
              <div className="slide-indicator">
                <span>{`0${currentSlide} / 0${slides.length}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyUsPage;
