import "./FirstSection.scss";
import ButtonComponent from "../Button/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function FirstSection() {
	const { t } = useTranslation();

	return (
		<section className="first-section">
			<div className="container">
				<div className="first-section__content">
					<div className="first-section__content-header">
						<h1>{t("h1")}</h1>
						<h2>{t("h1__2")}</h2>
						<div className="first-section__content-header__sub">
							<p>{t("firstsectioncontentheadersub")}</p>
							<span>{t("firstsectioncontentheadersub2")}</span>
						</div>
					</div>
					<div className="first-section__content-btn">
						<ButtonComponent url="#contact" text={t("firstsectioncontentbtn")} />
						<button className="servicesBtn">
							<Link to="/services">
							{t("firstsectioncontentbtn2")}</Link>
						</button>
					</div>
				</div>
			</div>
		</section>
	);
}

export default FirstSection;
