import React, { useState } from "react";
import "./ContactPage.scss";

import FiveSection from "../FiveSection/FiveSection";

function ContactPage() {
	return (
		<div className="contactpage__section">
			<div className="container">
				<FiveSection />
			</div>
		</div>
	);
}

export default ContactPage;
