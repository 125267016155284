import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Header from "../Header/Header";

import "./ThanksPage.scss";

import ok from "./images/ok.svg";
import arrow from "./images/arrow.svg";

function ThanksPage() {
	const { t } = useTranslation();
	return (
		<>
			<Header />

			<section className="thanks-page">
				<div className="container">
					<div className="thanks-page__content">
						<div className="thanks-page__content-nav">
							<Link to="/">
								<button className="thanks-page__content-nav__btn">
									<img src={arrow} alt="" />
								</button>
								<span>{t("thanksText0")}</span>
							</Link>
						</div>
						<div className="thank-page__content-text">
							<div className="thanks-page__content-text__img">
								<img src={ok} alt="" />
							</div>
							<div className="thanks-page__content-text__thx">
								<h3>{t("thanksText1")}</h3>
								<p>{t("thanksText2")}</p>
								<p>{t("thanksText3")}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ThanksPage;
