import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import "./Header.scss";
import logo from "./images/logo.svg";
import telegram from "./images/telegram.svg";
import phone from "./images/phone.svg";

function Header() {
  const { t } = useTranslation();
  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the location changes
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className={isScrolled ? "scrolled" : ""}>
      <div className="container">
        <div className="header__content">
          <div className="header__content-urls">
            <div className="header__content-urls__logo">
              <NavLink to="/">
                <img src={logo} alt="Golden Line" />
              </NavLink>
            </div>
            <div className="header__content-urls__menu">
              <nav>
                <LanguageSwitcher />
                <NavLink 
                  to="/rate" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem1")}
                </NavLink>
                <NavLink 
                  to="/services" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem2")}
                </NavLink>
                <NavLink 
                  to="/why-us" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem3")}
                </NavLink>
                <NavLink 
                  to="/how-to" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem4")}
                </NavLink>
                <NavLink 
                  to="/contacts" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem5")}
                </NavLink>
              </nav>
            </div>
          </div>
          <div className="header__content-btns">
            <button className="header__content-btns__telegram">
              <a href="https://t.me/test">
                <img src={telegram} alt="Telegram" />
              </a>
            </button>
            <button className="header__content-btns__phone">
              <a href="tel:+496170961709">
                <img src={phone} alt="Phone" />
                <span>{t("menuitem6")}</span>
                <span>+496170961709</span>
              </a>
            </button>
          </div>
          <div className="header__content-tablet">
            <div className="header__content-tablet__logo">
              <NavLink to="/">
                <img src={logo} alt="Golden Line" />
              </NavLink>
              <LanguageSwitcher />
            </div>
            <div className="header__content-tablet__btns">
              <button className="header__content-tablet__btns__telegram">
                <a href="https://t.me/test">
                  <img src={telegram} alt="Telegram" />
                </a>
              </button>
              <button className="header__content-tablet__btns__phone">
                <a href="tel:+80448764321">
                  <img src={phone} alt="Phone" />
                  <span>{t("menuitem6")}</span>
                  <span>+80448764321</span>
                </a>
              </button>
            </div>
            <div className="header__content-tablet__btns-mob">
              <div className="header__content-tablet__btns-mob__top">
                <button className="header__content-tablet__btns-mob__top__phone">
                  <a href="tel:+80448764321">
                    <img src={phone} alt="Phone" />
                    <span>{t("menuitem6")}</span>
                    <span>+80448764321</span>
                  </a>
                </button>
              </div>
              <div className="header__content-tablet__btns-mob__bottom">
                <button className="header__content-tablet__btns-mob__bottom__telegram">
                  <a href="https://t.me/test">
                    <img src={telegram} alt="Telegram" />
                  </a>
                </button>
                <LanguageSwitcher />
                <div
                  className={`header__content-tablet__btns-mob__bottom__burger ${
                    isMenuOpen ? "open" : ""
                  }`}
                  onClick={toggleMenu}
                >
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                </div>
              </div>
            </div>
            <div
              className={`header__content-tablet__burger ${
                isMenuOpen ? "open" : ""
              }`}
              onClick={toggleMenu}
            >
              <div className="bar1"></div>
              <div className="bar2"></div>
            </div>
            <div
              className={`header__content-tablet__nav ${
                isMenuOpen ? "open" : ""
              }`}
            >
              <nav>
                <NavLink 
                  to="/rate" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem1")}
                </NavLink>
                <NavLink 
                  to="/services" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem2")}
                </NavLink>
                <NavLink 
                  to="/why-us" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem3")}
                </NavLink>
                <NavLink 
                  to="/how-to" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem4")}
                </NavLink>
                <NavLink 
                  to="/contacts" 
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}>
                  {t("menuitem5")}
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
