import "./Footer.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import logo from "../Header/images/logo.svg";
import telegram from "../Header/images/telegram.svg";
import phone from "../Header/images/phone.svg";

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__content-items">
            <div className="footer__content-items__urls">
              <div className="footer__content-items__urls-logo">
                <NavLink to="/">
                  <img src={logo} alt="Golden Line" />
                </NavLink>
              </div>
              <div className="footer__content-items__urls-nav">
                <nav>
                  <div className="footer__content-items__urls-nav__first">
                    <NavLink 
                      to="/rate" 
                      className={({ isActive }) => (isActive ? "active" : "")}>
                      {t("menuitem1")}
                    </NavLink>
                    <NavLink 
                      to="/services" 
                      className={({ isActive }) => (isActive ? "active" : "")}>
                      {t("menuitem2")}
                    </NavLink>
                    <NavLink 
                      to="/why-us" 
                      className={({ isActive }) => (isActive ? "active" : "")}>
                      {t("menuitem3")}
                    </NavLink>
                  </div>
                  <div className="footer__content-items__urls-nav__first">
                    <NavLink 
                      to="/how-to" 
                      className={({ isActive }) => (isActive ? "active" : "")}>
                      {t("menuitem4")}
                    </NavLink>
                    <NavLink 
                      to="/contacts" 
                      className={({ isActive }) => (isActive ? "active" : "")}>
                      {t("menuitem5")}
                    </NavLink>
                  </div>
                </nav>
              </div>
            </div>
            <div className="footer__content-items__contact">
              <div className="footer__content-items__contact-items">
                <button className="footer__content-items__contact-items__telegram">
                  <a href="https://t.me/test">
                    <img src={telegram} alt="Telegram" />
                  </a>
                </button>
                <button className="footer__content-items__contact-items__phone">
                  <a href="tel:+80448764321">
                    <img src={phone} alt="Phone" />
                    <span>{t("menuitem6")}</span>
                    <span>+80448764321</span>
                  </a>
                </button>
              </div>
              <div className="footer__content-items__contact-copyright">
                <p>Golden Line © 2024. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
