import React, { useState } from "react";
import "./HowToPage.scss";

import FiveSection from "../FiveSection/FiveSection";

import arrow from "./images/arrow.svg";

import { useTranslation, Trans } from "react-i18next";

function HowToPage() {
	const { t } = useTranslation();

	return (
		<section className="howto-section">
			<div className="container">
				<div className="howto__content">
					<div className="howto__content-headertext">
						<h2>{t("howtoh2")}</h2>
						<p>{t("howtoh3")}</p>
					</div>
					<div className="howto__content-blocks">
						<div className="howto__content-blocks__item">
							<div className="howto__content-blocks__item-one">
								<img src={arrow} alt="" />
							</div>
							<div className="howto__content-blocks__item-two">
								<h4>{t("howto1h4")}</h4>
								<p>{t("howto1p1")}</p>
							</div>
						</div>
						<div className="howto__content-blocks__item">
							<div className="howto__content-blocks__item-one">
								<img src={arrow} alt="" />
							</div>
							<div className="howto__content-blocks__item-two">
								<h4>{t("howto2h4")}</h4>
								<p>{t("howto1p2")}</p>
							</div>
						</div>
						<div className="howto__content-blocks__item">
							<div className="howto__content-blocks__item-one">
								<img src={arrow} alt="" />
							</div>
							<div className="howto__content-blocks__item-two">
								<h4>{t("howto3h4")}</h4>
								<p>{t("howto1p3")}</p>
							</div>
						</div>
						<div className="howto__content-blocks__item">
							<div className="howto__content-blocks__item-one">
								<img src={arrow} alt="" />
							</div>
							<div className="howto__content-blocks__item-two">
								<h4>{t("howto4h4")}</h4>
								<p>{t("howto1p4")}</p>
							</div>
						</div>
					</div>
					<FiveSection />
				</div>
			</div>
		</section>
	);
}

export default HowToPage;
