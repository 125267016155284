import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../Button/Button";

import "./Modal.scss";
import x from './images/x.png';

function Modal({ isOpen, onClose }) {
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); // Добавлено состояние отправки
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return; // Предотвращение повторных кликов

        setIsSubmitting(true); // Установка состояния "отправляется"

        try {
            const response = await fetch("/sendToTelegram2.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    fullName,
                    phoneNumber,
                    email,
                    businessName
                }),
            });

            const responseData = await response.json();

            if (response.ok) {
                setErrorMessage("");
                setSuccessMessage(`${t("modalinputok")}`);
                setFullName("");
                setPhoneNumber("");
                setEmail("");
                setBusinessName("");

                // Редирект на /thanks после успешной отправки
                navigate("/thanks");
            } else {
                setErrorMessage(`${t("modalinputerror2")}`);
                setSuccessMessage("");
            }
        } catch (error) {
            console.error("Ошибка:", error);
            setErrorMessage(`${t("modalinputerror2")}`);
            setSuccessMessage("");
        } finally {
            setIsSubmitting(false); // Сброс состояния отправки
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal open">
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    <img src={x} alt="Close" />
                </span>
                {!successMessage && !errorMessage && (
                    <form onSubmit={handleSubmit} className="formModal">
                        <h2>{t("modalh1")}</h2>
                        <div className="formModal__inner">
                            <input
                                type="text"
                                placeholder={t("fivesectionplaceholder1")}
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                autoComplete="off"
                                required
                            />
                            <input
                                type="tel"
                                placeholder={t("fivesectionplaceholder2")}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                autoComplete="off"
                                required
                            />
                            <input
                                type="email"
                                placeholder={t("fivesectionplaceholder3")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                                required
                            />
                            <input
                                type="text"
                                placeholder={t("fivesectionplaceholder4")}
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                autoComplete="off"
                            />
                            <button type="submit" className="reset-button-styles" disabled={isSubmitting}>
                                {isSubmitting ? t("formSubmitting") : <ButtonComponent text={t("fivesectioncontentbtn")} />}
                            </button>
                        </div>
                    </form>
                )}
                {successMessage && (
                    <div className="success">
                        <h2>Готово!</h2>
                        <p>{successMessage}</p>
                    </div>
                )}
                {errorMessage && (
                    <div className="error">
                        <h2>{t("modalinputerror")}</h2>
                        <p>{errorMessage}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Modal;
