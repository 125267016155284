import React, { useState } from "react";
import "./FiveSection.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ButtonComponent from "../Button/Button";

function FiveSection() {
    const { t } = useTranslation();
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); // Добавлено состояние отправки
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return; // Предотвращаем многократные клики

        setIsSubmitting(true); // Устанавливаем состояние отправки

        try {
            const response = await fetch("/sendToTelegram.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    fullName,
                    phoneNumber,
                    email,
                    businessName
                }),
            });

            const responseData = await response.json();

            if (response.ok) {
                setErrorMessage("");
                setSuccessMessage(`${t("modalinputok")}`);
                setFullName("");
                setPhoneNumber("");
                setEmail("");
                setBusinessName("");

                navigate("/thanks");
            } else {
                setErrorMessage(responseData.error || "Ошибка при отправке данных в Telegram!");
                setSuccessMessage("");
            }
        } catch (error) {
            console.error("Ошибка:", error);
            setErrorMessage(`${t("modalinputerror")}`);
            setSuccessMessage("");
        } finally {
            setIsSubmitting(false); // Сбрасываем состояние отправки
        }
    };

    return (
        <section className="five-section" id="contact">
            <div className="container">
                <div className="five-section__content">
                    <div className="five-section__content-inner">
                        <form onSubmit={handleSubmit}>
                            <h4>{t("fivesectionh4")}</h4>
                            <p>{t("fivesectionh4p")}</p>
                            <input
                                type="text"
                                placeholder={t("fivesectionplaceholder1")}
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                autoComplete="off"
                                required
                            />
                            <input
                                type="tel"
                                placeholder={t("fivesectionplaceholder2")}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                autoComplete="off"
                                required
                            />
                            <input
                                type="email"
                                placeholder={t("fivesectionplaceholder3")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                                required
                            />
                            <input
                                type="text"
                                placeholder={t("fivesectionplaceholder4")}
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                autoComplete="off"
                            />
                            <button type="submit" className="reset-button-styles" disabled={isSubmitting}>
                                {/* Отображаем текст кнопки в зависимости от состояния */}
                                <ButtonComponent
                                    text={
                                        isSubmitting
                                            ? t("formSubmitting") // Текст во время отправки
                                            : errorMessage
                                                ? errorMessage
                                                : successMessage
                                                    ? successMessage
                                                    : t("fivesectioncontentbtn")
                                    }
                                />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FiveSection;
